.left_bar {
  max-width: 260px;
  width: 100%;
  min-height: calc(100vh - 71px);
  border-right: none;
  position: sticky;
  top: 0;
  background-color: rgb(224, 224, 224);
  border-color: rgb(224, 224, 224);
  color: rgba(0, 0, 0, 0.87);
}

.left_bar ul li {
  text-align: left;
  width: 100%;
  height: 60px;
  padding: 6px 16px;
  margin: 0;
  text-transform: none;
  border-radius: 0 !important;
  white-space: normal;
  line-height: 48px;
  position: relative;
  z-index: 2;
  box-shadow: none !important;
}

.left_bar ul {
  padding: 0;
  list-style-type: none;
  margin-top: 0px;
}

.left_bar ul a {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: #565656;
  text-decoration: none;
  font-size: 14px;
}

.left_bar ul a span {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.left_bar ul a i {
  font-size: 12px;
}

.left_bar ul li.active a {
  color: #ee8738 !important;
  font-weight: 500;
}

.left_bar ul li img {
  margin-right: 15px;
  object-fit: contain;
  width: 20px;
  height: 20px;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

@media (max-width: 960px) {
  /*  toggle */
  .menu-block {
    display: block !important;
  }

  .menu-none {
    display: none !important;
  }

  .myLinks {
    display: none;
  }
  ul.myLinks {
    position: fixed;
    top: 64px;
    z-index: 999;
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 5px #a1a1a1;
  }
  .left_bar {
    position: inherit;
  }

  .left_bar {
    max-width: none;
    width: inherit;
    min-height: auto;
    border-right: 0;
  }

  .left_bar ul li {
    padding: 0;
  }

  .left_bar ul li {
    height: auto;
  }

  .topnav a {
    color: white;
    padding: 4px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
  }

  .topnav a.icon {
    background: rgb(250, 96, 0);
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
  }

  .topnav a.icon span {
    color: white;
    margin-bottom: 0;
    line-height: 3px;
    background: white;
    width: 18px;
    height: 3px;
  }

  .topnav a.icon span:not(:last-child) {
    margin-bottom: 3px;
  }
}
